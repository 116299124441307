import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px', height: "90vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Link to="/" style={{ background: '#F38D00', padding: "9px", marginTop: "15px", fontWeight: "700", borderRadius: "12px", textDecoration: "none", color: "#ffff" }}>Go Back to Home</Link>
        </div>
    );
};

export default NotFound;

import React from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";
import grouplogo from "../../../utils/landingpage/images/group-logo.svg";
import DblueArrow from "../../../utils/landingpage/images/D-blue-arrow.svg";
import LblueArrow from "../../../utils/landingpage/images/L-blue-arrow.svg";
import groupimg from "../../../utils/landingpage/images/group_img.svg";
import logoimg from "../../../utils/landingpage/images/Amoha-Logo-png.png";
import logofacebook from "../../../utils/landingpage/images/logo_facebook.svg";
import logotwitter from "../../../utils/landingpage/images/logo_twitter.svg";
import logoyoutube from "../../../utils/landingpage/images/logo_youtube.svg";
import logoinsta from "../../../utils/landingpage/images/logo_insta.svg";

const Footer = () => {
  return (
    <>
      <section className="footer_main">
        <div className="footer_container">
          <div className="footer_looking">
            <div className="footer_child">
              <motion.div
                className="footer_child1"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="d-flex align-items-center gap-4">
                  <div className="footer_image">
                    <a href="#">
                      <img src={grouplogo} alt="Group-logo" />
                    </a>
                  </div>

                  <div className="footer_txt">
                    <h6>For Job Seekers</h6>
                    <h3>
                      Are you looking for
                      <br /> a job ?
                    </h3>
                  </div>
                </div>
                <div className="footer_link">
                  <a href="#">
                    Apply Now <img src={DblueArrow} alt="Arrow" />
                  </a>
                </div>
              </motion.div>

              <motion.div
                className="footer_child2"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="d-flex align-items-center gap-4">
                  <div className="footer_image">
                    <img src={groupimg} alt="Group-img" />
                  </div>
                  <div className="footer_txt">
                    <h6>For Recruiters</h6>
                    <h3>
                      Are you looking for a<br />
                      Candidate ?
                    </h3>
                  </div>
                </div>
                <div className="footer_link2">
                  <a href="#">
                    Post a Job <img src={LblueArrow} alt="Arrow" />
                  </a>
                </div>
              </motion.div>
            </div>
          </div>

          <motion.div
            className="footer_detail"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="footer_menu">
              <div className="footer_logo">
                <img src={logoimg} alt="Group-img" />
              </div>
              <div className="footer_link_write">
                <div className="footer_write">
                  <p>
                    In lateral hiring, we provide solutions for hiring right
                    from <br /> entry-level to CXO-level hiring across banking,
                    finance, <br /> IT, and non-IT industries with experienced
                    recruitment <br /> professionals in India.HRTech company
                    head quartered in xyz
                  </p>
                </div>
                <div className="footer_links">
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logofacebook} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logotwitter} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logoyoutube} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logoinsta} alt="Group-img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_line">
              <div className="footer_menu2">
                <h3>
                  <b>Quick link</b>
                </h3>
                <div className="footer_menu_anchers">
                  <a href="#">Solutions</a>
                  <a href="#">Campuses</a>
                  <a href="#">About</a>
                  <a href="#">Contact</a>
                </div>
              </div>
              <div className="footer_menu2">
                <h3>
                  <b>Legal</b>
                </h3>
                <div className="footer_menu_anchers">
                  <a href="#">Terms and Conditions</a>
                  <a href="#">Privicy Policy</a>
                </div>
              </div>
            </div>

            <div className="footer_menu2">
              <h3>
                <b>Services</b>
              </h3>
              <div className="footer_menu_anchers footer_menu_anchers_services">
                <a href="#">For Employee</a>
                <a href="#">For Employer</a>
                <a href="#">Forv Campuses</a>
              </div>
            </div>

            <div className="footer_menu4">
              <p>
                <h3>
                  <b>Contact</b>
                </h3>
                <span>+91 8898551247</span>
                <br />
                <span>Contact@gmail.com</span>
                <br />
              </p>
              <div className="footer_menu5">
                Address123 Vijay nagar Indore, 452010
              </div>
            </div>
          </motion.div>

          <div className="footer_foot">
            <div className="footer_condition">
              <hr />
              <p>Copyright © 2024 Start Naukri, all rights reserved</p>
            </div>
            <br />
            <div className="footer_end"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

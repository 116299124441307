import "../../../styles/landing_page/contact.css";
import "react-phone-input-2/lib/style.css";
import React from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import Counter from "../../common_component/counter/counter";
import Faq from "../../common_component/faq/faq";
import mail from "../../../utils/landingpage/images/mail.svg";
import address from "../../../utils/landingpage/images/location.svg";
import phone from "../../../utils/landingpage/images/phone.svg";

function Contact() {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "First Name must be at least 2 characters")
        .matches(/^[A-Za-z]+$/, "Only letters are allowed")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Last Name must be at least 2 characters")
        .matches(/^[A-Za-z]+$/, "Only letters are allowed")
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email format"
        )
        .required("Required"),
      contact: Yup.string()
        .required("Required")
        .test("valid-contact", "Invalid phone number format", (value) => {
          value = "+".concat(value);
          if (!value) return false;

          if (value.startsWith("+91")) {
            return /^\+91[6-9]\d{9}$/.test(value);
          } else {
            return /^\+\d{1,4}\d{10,}$/.test(value);
          }
        }),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      alert(
        JSON.stringify({ ...values, contact: `+${values.contact}` }, null, 2)
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <section className="contact_parent">
          <section className="contact_header">
            <h4>Contact Us</h4>
            <h2>Contact our friendly team</h2>
            <h6>Let us know how we can help you.</h6>
          </section>
          <section className="contact_form_main">
            <div className="contact_form_container row">
              <div className="contact_form_section_one">
                <h3>Contact Information</h3>
                <p className="contact_form_section_one_subheading">
                Get in touch with us:
                </p>
                <div>
                  <img src={phone} alt="phone" />
                  <p>+91 9687911144</p>
                </div>
                <div>
                  <img src={mail} alt="mail" />
                  <p> abhayyksharma@amoha.biz</p>
                </div>
                <div>
                  <img src={address} alt="address" />
                  <p>
                    705 & 706 The Gateway, Wadhwa Atmosphere, Mulund Goregaon Link
                    Road, Mumbai 400080.
                  </p>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} className="contact_form row">
                <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="contact_error_msg">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="contact_error_msg">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="contact_error_msg">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3">
                  <label htmlFor="contact">Phone No.</label>
                  <PhoneInput
                    country={"in"}
                    placeholder="Enter your number"
                    value={formik.values.contact}
                    onChange={(value) =>
                      formik.setFieldValue("contact", `${value}`)
                    }
                  />
                  {formik.touched.contact && formik.errors.contact ? (
                    <div className="contact_error_msg">
                      {formik.errors.contact}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="message">How can we help you?</label>
                  <textarea
                    name="message"
                    id="message"
                    rows={3}
                    placeholder="Message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    className="contact_message_input"
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="contact_error_msg">
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 input_submit_button_container mt-3">
                  <button type="submit" className="contact-submit-button">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </section>
        </section>
      </div>

      <div id="counter_contact">
        <Counter />
      </div>

      <Faq />
    </>
  );
}

export default Contact;

import { createSlice } from "@reduxjs/toolkit";

const LetralHiringSlice = createSlice({
  name: "letralHiring",
  initialState: {
    letralHiringJobsData: [],
    letralHiringUserData: [],
  },
  reducers: {
    setLetralHiringJobsData: (state, action) => {
      console.log('--action.pay6loadd====', action)
      state.letralHiringJobsData = action.payload;
    },
    setLetralHiringUserData: (state, action) => {
      state.letralHiringUserData = action.payload;
    },
    updateLetralHiringJobData: (state, action) => {
      state.letralHiringJobsData = state.letralHiringJobsData.map((job) => {
        if (job.id === action.payload) {
          return { ...job, is_already_saved: !job.is_already_saved };
        }
        return job;
      });
    },
  },
});

// Export Actions
export const {
  setLetralHiringJobsData,
  updateLetralHiringJobData,
  setLetralHiringUserData,
} = LetralHiringSlice.actions;

// Export Reducer
export default LetralHiringSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

// Slice Definition
const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        error: null,
        loginResponse: {}
    },
    reducers: {
        loading: (state, action) => {
            state.loading = action.payload;
        },
        logOut: (state, action) => {
            state.user = action.payload;
        },
        logInReducerResponse: (state, actions) => {
            state.loginResponse = actions.payload;
        }
    }
});

// Export Actions
export const { loading, logOut, logInReducerResponse } = authSlice.actions;

// Export Reducer
export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const StudentProfileSlice = createSlice({
    name:"studentProfile",
    initialState: {
        getStudentProfileData: [],
        isEditModelOpen: false,
        isSubmitted: false,
    },
    reducers:{
        setGetStudentProfileData: (state, action) => {
            state.getStudentProfileData = action.payload;
        },
        setIsSubmittedCertification: (state, action) => {
            state.isEditModelOpen = action.payload;
        },
        setIsSubmitted: (state, action) => {
            state.isSubmitted = action.payload;
        }
    }
});

export const {setGetStudentProfileData, setIsEditModelOpen, setIsSubmitted} = StudentProfileSlice.actions;

export default  StudentProfileSlice.reducer;
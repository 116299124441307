import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../styles/landing_page/landingpage.css";
import logo from "../../../utils/landingpage/images/Amoha-Logo-png.png";
import Vector from "../../../utils/landingpage/images/Vector.svg";
import Menuicon from "../../../utils/landingpage/images/menuiconTwo.svg";
import Downarrow from "../../../utils/landingpage/images/down-arow.svg";
import notificationIcon from "../../../utils/landingpage/images/notificationIcons.png";
import userIcon from "../../../utils/landingpage/images/logdedUser.png";
import LogOut from "../../reusablecomponents/Logout";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [isToken, setIsToken] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));

  const toggleMenu = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  const handleClickOutside = (event) => {
    if (
      !sidebarRef.current.contains(event.target) &&
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function hanldeRegister() {
    window.location.href = "https://startnaukri.com/lateral-hiring";
  }

  function handleProfileClick() {
    navigate("/lateral/myProfile");
  }

  function handleLogin() {
    window.location.href = "https://startnaukri.com/login";
  }

  useEffect(() => {
    const getToken = sessionStorage.getItem("accessToken");
    setIsToken(getToken);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="nav_bar_main">
        <div className="nav_container">
          <div className="nav_row">
            <div className="nav_logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="nav_tabs">
              <ul>
                <li>
                  <p className={location.pathname == "/" ? "" : ""}>
                    <Link to="/">Home</Link>
                  </p>
                </li>

                <li>
                  <p
                    className={
                      location.pathname == "/about" ? "nav-active-tabs" : ""
                    }
                  >
                    <Link to="/about">About</Link>
                  </p>
                </li>
                <li>
                  <p
                    className={
                      location.pathname == "/contact" ? "nav-active-tabs" : ""
                    }
                  >
                    <Link to="/contact">Contact</Link>
                  </p>
                </li>
              </ul>
            </div>

            {isToken == null ? (
              <div className="nav_login">
                <button onClick={handleLogin}>
                  <p>Login</p>
                </button>

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  id="btn"
                  onClick={hanldeRegister}
                >
                  Sign Up
                </motion.button>
              </div>
            ) : (
              <div className="user-profile-container">
                <div className="position-relative">
                  <img
                    src={notificationIcon}
                    width="22.5px"
                    height="24px"
                    alt="..."
                  />
                  <div className="user_notif_cont"></div>
                </div>
                <div className="user-profile" onClick={toggleDropdown}>
                  <img
                    src="https://www.gcsmc.org/assets/images/user.png"
                    alt="user img"
                    className="avatar"
                  />
                  <span className="name">
                    (lateral-Std){userData.name ? userData.name : `User`}
                  </span>
                  <span className={`dropdown-icon ${isOpen ? "open" : ""}`}>
                    ▼
                  </span>
                </div>
              </div>
            )}
            <div ref={menuButtonRef} className="menu_icon" onClick={toggleMenu}>
              <img src={Menuicon} alt="menu-icon" />
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile and tablet view menu  start */}
      <div ref={sidebarRef} className={`sidebar ${menu ? "open " : "closed "}`}>
        <ul>
          <li>
            <p>
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </p>
          </li>

          <li>
            <p>
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link to="/contact" onClick={toggleMenu}>
                Contact
              </Link>
            </p>
          </li>
          <li>
            <button
              onClick={handleLogin}
              className="menu_login_btn btn border w-100 "
            >
              <p>Login</p>
            </button>
          </li>
          <li>
            <button
              onClick={hanldeRegister}
              className="menu_sign_up_btn btn   text-light  w-100"
            >
              <p>Sign Up</p>
            </button>
          </li>
        </ul>
      </div>
      {/* Mobile and tablet view menu  end   */}
    </>
  );
};

export default Navbar;

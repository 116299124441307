import About from "../components/landing_page/about/about";
import Contact from "../components/landing_page/contact/contact";
import LateralHiring from "../components/landing_page/lateralhiring/lateralhiring";


export const navigateRoutes = [
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/",
    component: <LateralHiring />,
  },
];

import React, { createContext, useState, useEffect, useContext } from 'react';

const OnlineStatusContext = createContext();

export const useOnlineStatus = () => useContext(OnlineStatusContext);

const OnlineStatusProvider = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showOfflineModal, setShowOfflineModal] = useState(false);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setShowOfflineModal(false);
        };
        const handleOffline = () => {
            setIsOnline(false);
            setShowOfflineModal(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <OnlineStatusContext.Provider value={{ isOnline, showOfflineModal }}>
            {children}
            {showOfflineModal && (
                <div className="offline-modal">
                    <p>You are offline. Please check your internet connection.</p>
                </div>
            )}
        </OnlineStatusContext.Provider>
    );
};

export default OnlineStatusProvider;

import React, { useState, useRef, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import "../../../styles/landing_page/landingpage.css";
import axios from "axios";

const Counter = () => {
  const [counters, setCounters] = useState([]);
  const [startCounting, setStartCounting] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/landing_page/`
        );

        if (
          Array.isArray(response.data) &&
          response.data.length > 0 &&
          response.data[0].counters
        ) {
          setCounters(response.data[0].counters);
        } else {
          console.error("Unexpected response structure:", response.data);
        }

        setStartCounting(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const parseRating = (rating) => {
    return parseInt(rating.replace(/[^0-9]/g, ""), 10);
  };

  const formatNumber = useCallback((num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}k +`;
    }
    return num;
  }, []);

  return (
    <>
      <section className="counter_main">
        <div className="counter_container">
          <div className="counter_row">
            <div className="counter_value row ">
              {counters.map((data, index) => (
                <div key={data.id} className="counter-inner col-6 col-md-3">
                  {startCounting && (
                    <CountUp
                      end={`${parseRating(data.rating)}`} // Ensure to parse the rating to a number
                      duration={2}
                      separator=","
                      formattingFn={formatNumber}
                    />
                  )}
                  <h6>{data.title}</h6>
                </div>
              ))}

              {/* <div className="counter-inner  col-6  col-md-3">
                {startCounting && (
                  <CountUp
                    end={20000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>People Got Hired</h6>
              </div>
              <div className="counter-inner pt-2 pt-md-0 col-6 col-md-3">
                {startCounting && (
                  <CountUp
                    end={2000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>Companies</h6>
              </div>
              <div className="counter-inner pt-2 pt-md-0 col-6 col-md-3">
                {startCounting && (
                  <CountUp
                    end={10000}
                    duration={2}
                    separator=","
                    formattingFn={formatNumber}
                  />
                )}
                <h6>Jobs Posted</h6>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;

import React from 'react';
import { Navigate } from 'react-router-dom';

// ProtectedRoute component to manage access to protected routes
const ProtectedRoute = ({ element: Component, ...rest }) => {
  // Replace this with your authentication logic
  const isAuthenticated = sessionStorage.getItem('accessToken') !== null;

  // If the route is protected and the user is not authenticated, redirect to login
  return isAuthenticated ? Component : <Navigate to="/login" />;
};

export default ProtectedRoute;

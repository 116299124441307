import React, { useEffect } from 'react';
import Navbar from '../common_component/navigation/navbar';
import Footer from '../common_component/footer/footer';
import { useLocation } from 'react-router-dom';
import { useOnlineStatus } from '../reusablecomponents/CheckOnline';
import { toast, ToastContainer } from 'react-toastify';

const Layout = ({ children }) => {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (!isOnline) {
      toast.error('Please connect to the internet');
    }
  }, [isOnline]);

  return (
    <div>
      <ToastContainer />
      {/* Use parentheses to properly group the conditional rendering */}
      {(
        location.pathname === "/404" ||
        location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname.includes('lateral/register') ||
        location.pathname.includes('auth/userMessage')
      ) ? (
        children
      ) : (
        <>
          <Navbar />
          {children}
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;

import "../../../styles/landing_page/about.css";
import { useEffect } from "react";
import Services from "../../common_component/services/services";
import Testimonial from "../../common_component/testimonial/testimonial";
import chair from "../../../utils/landingpage/images/about_bg.png";
import beg from "../../../utils/landingpage/images/beg.svg";
import check from "../../../utils/landingpage/images/check.svg";
import user from "../../../utils/landingpage/images/user.svg";
import file from "../../../utils/landingpage/images/file.svg";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="about_main">
        <div className="about_header text-center">
          <h4>About Us</h4>
          <h2>About the Platform</h2>
          <h6>Learn about the company and the team behind it.</h6>
          <button className="btn">Book a Demo</button>
        </div>
      </section>
      {/* First section start */}
      {/* second section start */}

      <section class="about_section">
        <section>
          <div class="about_container row">
            <div class="about_image_container col-4 row-12">
              <img
                src={chair}
                alt="Office Image"
                class="about_image img-fluid"
              />
            </div>
            <div class="about_text_section col-12 col-lg-8 ">
              <div class="about_showcase ">
                <div class="about_second_section_content">
                  <h6>Expertise in Lateral Hiring and Entrepreneurship</h6>
                  <h3>
                    Amoha Consulting & Ventures: A Leader in Lateral Hiring
                  </h3>
                  <p>
                    <strong>
                      “Amoha Consulting & Ventures Private Limited”{" "}
                    </strong>{" "}
                    is a group company of <strong>Amoha Group</strong>,
                    primarily into lateral hiring (entry-level to CXO-level
                    hiring).
                    <strong> Founder and Group MD, Mr. Ajay Singh</strong>, has
                    been into retail branch banking in banks like the{" "}
                    <strong>Bank of India, ICICI Bank, HDFC Bank</strong>, and{" "}
                    <strong>AU Small Finance Bank</strong>. As Senior Vice
                    President and Head of Branch Banking in the MP Chhattisgarh
                    region during his retail banking career of over 22 years, he
                    has been a serial entrepreneur and has created dozens of
                    successful startups.
                  </p>

                  <p>
                    {" "}
                    Founder and Group CEO Mr.{" "}
                    <strong> Abhay Y K Sharma </strong> has been into retail
                    banking for 16 years in various organizations like Citibank,
                    Reliance, IndusInd Bank, Kotak Mahindra Bank, IndiaBulls
                    Housing Finance, and Axis Bank Limited into retail landing
                    and since the last 4 years plus into hiring solutions for
                    Corporations like Lateral Hiring, Fresher Hiring, Interns
                    Hiring, Apprenticeship Hiring, and Senior Management Hiring
                    during his tenure as a Partner and{" "}
                    <strong>CEO of Acura Solution, </strong>where he managed
                    more than 100 corporate like Lateral Hiring, Fresher Hiring,
                    Interns Hiring, Apprenticeship Hiring and Senior Management
                    Hiring while is tenure as a partner and CEO of Accura
                    Solution where he haid managed more than 100 corporate
                    clients primarily focused into Banking and Finance across
                    the vertical, including IT and Non IT Hiring.
                  </p>
                  {/* <p>
                    Founder and Group CEO <strong>Mr. Abhay Y K Sharma</strong>{" "}
                    has over
                    <strong>16 years of experience</strong> in retail banking
                    with organizations like{" "}
                    <strong>
                      Citibank, Reliance Bank, Indusind Bank, Kotak Mahindra
                      Bank, IndiaBulls Housing Finance
                    </strong>
                    , and <strong>Axis Bank Limited</strong>
                    in retail lending. For the past 4+ years, he has focused on
                    <strong>hiring solutions</strong> (lateral hiring, fresher
                    hiring, interns hiring, apprenticeship hiring, and senior
                    management hiring). As a
                    <strong>Partner and CEO of Accura Solution</strong>, he
                    managed
                    <strong>over 100 corporate clients</strong>, primarily in
                    banking, finance, IT, and non-IT sectors.
                  </p> */}
                </div>
              </div>
              {/* <div class="about_second_section_box row">
                <div className="col-sm-4 col-12 ">
                  <div class="box">
                    <h3>
                      20K<span>+</span>
                    </h3>
                    <h4>Students Placed</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div class="box">
                    <h3>
                      5K<span>+</span>
                    </h3>
                    <h4>Companies Registered</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div class="box">
                    <h3>
                      2K<span>+</span>
                    </h3>
                    <h4>Campuses</h4>
                  </div>
                </div>
              </div> */}
            </div>

            <div class="about_section_line"></div>
          </div>
        </section>

        <section></section>

        <section class="how_it_works">
          <div class="how_it_work_container">
            <h2>How it works</h2>
            <p className="text-center w-100  sm-w-50 md-w-50 lg-w-50 xl-w-50">
              Our platform simplifies your job search process, guiding you from
              creating an account to successfully applying for jobs. Follow
              these simple steps to get started on your career journey.
            </p>
            <div class="row  p-0 ">
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={user} alt="" />
                  </div>
                  <h4>Create Account</h4>
                  <p>
                    Register with us to unlock access to thousands of job
                    opportunities across various industries.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={file} alt="" />
                  </div>
                  <h4>Upload Resume</h4>
                  <p>
                    Easily upload your resume to showcase your skills and
                    experience to potential employers.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={beg} alt="" />
                  </div>
                  <h4>Find Jobs</h4>
                  <p>
                    Browse through tailored job listings to find positions that
                    best match your skills and career goals.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6  col-xl-3 col-lg-4 d-flex justify-content-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={check} alt="" />
                  </div>
                  <h4>Apply Job</h4>
                  <p>
                    Submit your applications directly through our platform, and
                    track your application status with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* second section end */}

      <Services />

      <Testimonial />
    </>
  );
}

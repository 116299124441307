import React from "react";
import { motion } from "framer-motion";
import "../../../styles/landing_page/landingpage.css";

const Services = () => {
  return (
    <>
      <section className="service_main">
        <div className="service_container">
          <div className="service_container_semi">
            <motion.div
              className="service_container_content"
              initial={{ y: -60, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h2>
                <span>We provide service for job seekers</span>
                <span> and employers</span>
              </h2>
            </motion.div>
            <motion.div
              className="service_container_description"
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 2 }}
            >
              <b>
                Find the best paying jobs based on the job category, 100% safe
                recruitment process with transparent progress and real-time
                assessment.
              </b>
            </motion.div>
            <div className="service_content_btn">
              <motion.button
                initial={{ y: 60, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Book a Demo
              </motion.button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;

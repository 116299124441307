import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../components/layout/index.jsx';
import { navigateRoutes } from './route.js';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute
import NotFound from '../NotFound .js';

const RoutesHead = () => {
  return (
    <div>
      <BrowserRouter>
        <Layout>
          <Routes>
            {navigateRoutes.map((val, idx) => (
              <Route
                key={idx}
                path={val.path}
                element={
                  val.protected ? (
                    <ProtectedRoute element={val.component} />
                  ) : (
                    val.component
                  )
                }
              />
            ))}
            {/* Page Not Found  */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default RoutesHead;
